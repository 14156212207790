.searchList {
  position: absolute;
  top: 100%;
  background: var(--content-color);
  border: 1px solid var(--border-color);
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 1000;
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}

.subtitle {
  font-size: 0.75rem;
  color: var(--text-color-secondary);
}
