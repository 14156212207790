ol.list,
.list ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.day {
  margin-bottom: 1.5rem;
  background-color: var(--content-color);
}
