.list {
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
}

.listItem {
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  position: relative;
}

.listItem:hover {
  background-color: var(--content-hover);
}
