.weekHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: flex-end;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
}
.weekHeader h2 {
  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
}
