.button {
  margin: 0;
  display: inline-flex;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
}

.button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--focus-shadow);
}

.primary {
  color: var(--primary-color-text);
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.primary:hover {
  background: var(--primary-hover-color);
  border-color: var(--primary-hover-color);
}

.primary:active {
  background: var(--primary-active-color);
  border-color: var(--primary-active-color);
}

.outlined {
  color: var(--primary-color);
  background: var(--content-color);
  border: 1px solid var(--primary-color);
}

.outlined:hover {
  background: var(--content-hover);
}

.outlined:active {
  background: var(--content-active);
}

.plainText {
  color: var(--primary-color);
  background: var(--content-color);
  border: 1px solid var(--content-color);
}

.plainText:hover {
  background: var(--content-hover);
}

.plainText:active {
  background: var(--content-active);
}
