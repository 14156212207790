.foodJournal {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.foodJournal ol {
  list-style: none;
  padding: 0;
  padding-top: 1.5rem;
  margin: 0;
}

.loadMore {
  align-self: center;
}
