.formContainer {
  padding: 0 1rem;
  display: grid;
  gap: 1rem 1rem;
  justify-items: stretch;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "title   title   title  title  title  title"
    "weight  weight  weight cal   cal   cal"
    "protein protein fat    fat    carbs  carbs"
    "date    date    date   date   date   date"
    "submit  submit  submit submit submit submit";
}

.title {
  position: relative;
  grid-area: title;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.searchBox {
  flex-grow: 1;
}

.weight {
  grid-area: weight;
}

.cal {
  grid-area: cal;
}

.protein {
  grid-area: protein;
}

.fat {
  grid-area: fat;
}

.carbs {
  grid-area: carbs;
}

.date {
  grid-area: date;
}

.submit {
  grid-area: submit;
}
