.field {
  position: relative;
  display: block;
}

.field input {
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height);
  color: var(--text-color-primary);
  background: var(--content-color);
  width: 100%;
  padding: 0.75rem 0.75rem;
  border: 1px solid var(--border-color);
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: var(--border-radius);
}

.field input:focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: var(--primary-color);
}

.field input:hover {
  border-color: var(--primary-color);
}

.field input::-webkit-outer-spin-button,
.field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field input[type="number"] {
  -moz-appearance: textfield;
}

.field label {
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0.75rem;
  left: 0.25rem;
  padding: 0 0.5rem;
  background: var(--content-color);
  color: var(--text-color-secondary);
  transition: transform 0.2s;
  border-radius: 6px;
}

.field:focus-within label {
  color: var(--primary-color);
}

.label-raised label {
  transform: translateY(-1.5rem) scale(0.75);
  transition: transform 0.2s;
}

.suffix {
  color: var(--text-color-secondary);
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.with-suffix input {
  padding-right: 2.5rem;
}

.align-right input {
  text-align: right;
}

.disabled {
  opacity: var(--disabled-opacity);
}

.disabled.field input:hover {
  border-color: var(--border-color);
}
