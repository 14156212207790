.wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}

.ink {
  position: absolute;
  top: 0;
  left: 0;
  width: 6rem;
  height: 6rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  transform: scale(0);
}

.inkActive {
  animation: ripple 0.4s linear;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2.5);
  }
}
