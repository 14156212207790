:root {
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --maskbg: rgba(0, 0, 0, 0.4);
  --focus-shadow: 0 0 0 1px #e0d8fc;
  --primary-color: #b19df7;
  --primary-color-text: #212529;
  --primary-active-color: #9378f4;
  --primary-hover-color: #a28af5;
  --appbar-color: #4b2ac1;

  --font-family: -apple-system, Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  --font-size: 14px;
  --font-weight: 500;
  --line-height: 1.5;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 800;
  --text-color-primary: #e6e6e6;
  --text-color-secondary: #b3b3b3;
  --error-color: #832225;
  --error-message-color: #c84448;
  --disabled-opacity: 0.4;
  --ground-color: #1d1e27;
  --content-color: #282936;
  --content-hover: rgba(177, 157, 247, 0.04);
  --content-active: rgba(177, 157, 247, 0.16);
  --raised-color: #333544;
  --border-color: #3e4053;
  --border-radius: 6px;
  --selected-color: #4f34b2;
}

body {
  margin: 0;
}

html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height);
  color: var(--text-color-primary);
  background-color: var(--ground-color);
}

input,
textarea {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}
