.recordLine {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  transition: background-color 0.15s ease-out;
}

.not-filled-in {
  background-color: var(--error-color);
}

.subtitle {
  font-size: 0.75rem;
  color: var(--text-color-secondary);
}

.right {
  max-width: 22%;
}

.selected {
  background-color: var(--selected-color);
}
